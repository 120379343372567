<template>
    <div class="viewBoxGlobal" style="background:var(--background)">
        <div class="box">
            <div class="classList">
                <div @click="changeClass(index)" :class="index == current ? 'classItem active buttonTransition' : 'classItem buttonTransition'" v-for="(item,index) in classList" :key="index">
                    <span v-text="item.text"></span>
                </div>
            </div>
            <div class="searchBox">
                <DatePicker style="margin-bottom:1rem;margin-right:1rem" :startDate.sync="searchInfo.createTime[0]" :endDate.sync="searchInfo.createTime[1]"></DatePicker>
                <Select style="margin-bottom:1rem;" :change="changeSelectVisible" :visible.sync="selectVisible" :list="typeList" :activeType="activeType"></Select>
                <div style="margin-bottom:1rem;" class="searchButton buttonTransition" @click="toSearch">
                    {{ $t("Search") }}
                    <span style="margin-left:0.3rem" v-loading="loading"></span>
                </div>
            </div>
            <TableSimple :statusClick="statusClick" :column="column[current]" :data="data" ></TableSimple>
            <div v-if="data.length < total" style="text-align: center;cursor:pointer">
                <div @click="getMore" class="loadingMore">{{ $t("LoadMore") }}</div>
            </div>
            <div class="fixedStatistics" v-if="current == 3">
                <div class="fixedStatisticsLeft">
                    <span class="fixedStatisticsLeftDate">{{ $t("Current") }}</span>
                    <span class="fixedStatisticsLeftTotal">{{ data.length }}{{ $t("Records") }}</span>
                </div>
                <div class="fixedStatisticsRight">
                    <div class="fixedStatisticsRightItem">
                        <span>R$</span>
                        <span v-text="betsSum"></span>
                    </div>
                    <div :class="bonusSum >= 0 ? 'fixedStatisticsRightItem green' : 'fixedStatisticsRightItem red'">
                        <span>R$</span>
                        <span v-text="bonusSum"></span>
                    </div>
                </div>
            </div>
        </div>

        <Modal :visible.sync="rejectVisible" :title="$t('RejectionReason')">
            <div style="margin-top:1rem;font-size:1.6rem;color:#fff;font-weight:bold" v-text="rejectText"></div>
        </Modal>
    </div>
</template>

<script>

    import DatePicker from "@/components/DatePicker.vue";
    import TableSimple from "@/components/TableSimple.vue";
    import Modal from "@/components/Modal.vue";
    import SimpleProcess from "@/components/SimpleProcess.vue";
    import Select from "@/components/Select.vue";

    import { formatDateTimeNotYearSimple,formatDateTime,formatWord } from "@/utils/dateUtil.js"
    import { orderPage, withdrawalPage } from "@/api/user"
    import { GameRecordList,BalanceChangeList,BonusRecord } from "@/api/report"
    import { UserTaskInfo } from "@/api/deposit";
    import { GetGameType } from "@/api/base";

    export default {
        name:"Report",
        components:{
            DatePicker,
            TableSimple,
            Modal,
            SimpleProcess,
            Select
        },
        computed:{
            betsSum(){
                return this.data.map(item => item.bets).reduce((acc, num) => acc + num, 0).toFixed(2);
            },
            bonusSum(){
                return this.data.map(item => {
                    let temp = item.winloss;
                    if(item.changeType){
                        temp = parseFloat("-" + item.winloss);
                    }
                    return temp;
                }).reduce((acc, num) => acc + num, 0).toFixed(2);
            },
            selectVisible(){
                return this.current == 3;
            }
        },  
        data(){
            return {
                current:0,
                classList:[
                    { text:this.$t("Account") },
                    { text:this.$t("Deposit") },
                    { text:this.$t("Withdraw") },
                    { text:this.$t("Bets") },
                    { text:this.$t("Bonus") },
                    { text:this.$t("Promotion") },
                ],
                column:[
                    [ // 账户资金变动
                        {
                            name:this.$t("TransactionID"),
                            key:"id"
                        },
                        {
                            name:this.$t("Date"),
                            key:"date"
                        },
                        {
                            name:this.$t("Type"),
                            key:"type"
                        },
                        {
                            name:this.$t("Amount"),
                            key:"amount"
                        },
                    ],
                    [ // 充值列表
                        {
                            name:this.$t("TransactionID"),
                            key:"id"
                        },
                        {
                            name:this.$t("Date"),
                            key:"date"
                        },
                        {
                            name:this.$t("DepositAmount"),
                            key:"depositAmount"
                        },
                        {
                            name:this.$t("Bonus"),
                            key:"bonus"
                        },
                        {
                            name:this.$t("Status"),
                            key:"status"
                        }
                    ],
                    [ // 提现列表
                        {
                            name:this.$t("TransactionID"),
                            key:"id"
                        },
                        {
                            name:this.$t("Date"),
                            key:"date"
                        },
                        {
                            name:this.$t("WithdrawAmount"),
                            key:"withdrawAmount"
                        },
                        {
                            name:this.$t("WithdrawFee"),
                            key:"bonus"
                        },
                        {
                            name:this.$t("Status"),
                            key:"status"
                        }
                    ],
                    [ // 游戏记录列表
                        {
                            name:this.$t("TransactionID"),
                            key:"id"
                        },
                        {
                            name:this.$t("Date"),
                            key:"date"
                        },
                        {
                            name:this.$t("Type"),
                            key:"type"
                        },
                        {
                            name:this.$t("Bets"),
                            key:"bets"
                        },
                        {
                            name:this.$t("Bonus"),
                            key:"bonus"
                        }
                    ],
                    [ // 奖金列表
                        {
                            name:this.$t("TransactionID"),
                            key:"id"
                        },
                        {
                            name:this.$t("Date"),
                            key:"date"
                        },
                        {
                            name:this.$t("Origin"),
                            key:"source"
                        },
                        {
                            name:this.$t("Bonus"),
                            key:"bonus"
                        },
                        {
                            name:this.$t("Status"),
                            key:"status"
                        }
                    ],
                    [ // 活动列表
                        {
                            name:this.$t("TransactionID"),
                            key:"id"
                        },
                        {
                            name:this.$t("Date"),
                            key:"date"
                        },
                        {
                            name:this.$t("Type"),
                            key:"type"
                        },
                        {
                            name:this.$t("Bonus"),
                            key:"bonus"
                        },
                        {
                            name:this.$t("Details"),
                            key:"details"
                        },
                    ]
                ],
                searchInfo:{
                    createTime:["",""]
                },
                data:[

                ],
                total:0,
                page:1,
                size:50,
                loading:false,
                rejectVisible:false,
                rejectText:"",
                activeType:-1,
                typeList:[
                ],
            }
        },
        methods:{
            initGameType(){
                GetGameType().then(res => {
                    if(res.code == 200){
                        this.typeList = res.data;
                    }
                })
            },
            changeSelectVisible(item,index){
                this.activeType = index;
            },
            statusClick(item){
                if(item.statusN == 4){
                    this.rejectText = item.remark;
                    this.rejectVisible = true;
                }
            },
            changeClass(index){
                if(!this.loading){
                    this.current = index;
                    this.toSearch();
                }
            },
            getMore(){ // 获取更多
                switch(this.current){
                    case 0:
                        this.getBalanceChangeList();
                        break;
                    case 1:
                        this.getDepositList();
                        break;
                    case 2:
                        this.getWithdrawList();
                        break;
                    case 3:
                        this.getGameRecordList();
                        break;
                    case 4:
                        this.getBonusRecordList();
                        break;
                    case 5:
                        this.getUserTaskInfoList();
                        break;
                }
            },
            getGameType(code){
                switch(code){
                    case 1:
                        return this.$t("VideoGame")
                    case 2:
                        return this.$t("BoardGame")
                    case 3:
                        return this.$t("GameLobby")
                    case 5:
                        return this.$t("FishingGame")
                    case 8:
                        return this.$t("BingoBettingMachine")
                    case 11:
                        return this.$t("CrashGame")
                    case 12:
                        return this.$t("LiveVideo")
                    case 13:
                        return this.$t("Lottery")
                    case 14:
                        return this.$t("Sports")
                    default:
                        return "";
                }
            },
            toSearch(){
                this.page = 1;
                this.data = [];
                this.total = 0;
                this.getMore();
            },
            getWithdrawList(){ // 加载提现列表
                if(!this.loading){
                    this.loading = true;
                    const data = {
                        currentPage: this.page,
                        pageSize:this.size,
                        "createTime[0]":this.searchInfo.createTime[0],
                        "createTime[1]":this.searchInfo.createTime[1],
                    };
                    withdrawalPage(data).then(res => {
                        this.data = [...this.data,...res.data.records.map(item => {
                            return {
                                id:item.accountNo,
                                date:item.createTime ? formatDateTimeNotYearSimple(item.createTime,true) : '',
                                // date:formatDateTimeNotYearSimple(item.createTime,true),
                                withdrawAmount:item.withdrawAmount,
                                bonus:item.bonus,
                                status:item.status == 1 ? this.$t("CompletePayment") : item.status == 3 ? this.$t("Failure") : item.status == 2 ? this.$t("Pending") : item.status == 0 ? this.$t("Submitted") : this.$t("Rejected"),
                                statusc:item.status == 1 ? 'green' : item.status == 3 ? 'gray' : item.status == 2 ? 'red' : item.status == 0 ? 'gray' : 'red',
                                statusN:item.status,
                                remark:item.remark
                            }
                        })];
                        this.total = res.data.total;
                        this.loading = false;
                    }).catch(err => {
                        this.loading = false;
                    })
                }
            },
            getDepositList(){ // 加载充值列表
                if(!this.loading){
                    this.loading = true;
                    const data = {
                        currentPage: this.page,
                        pageSize:this.size,
                        "createTime[0]":this.searchInfo.createTime[0],
                        "createTime[1]":this.searchInfo.createTime[1],
                    };
                    orderPage(data).then(res => {
                        this.data = [...this.data,...res.data.records.map(item => {
                            return {
                                id:item.orderNum,
                                date:item.createTime ? formatDateTimeNotYearSimple(item.createTime,true) : '',
                                bonus:item.cashBonus,
                                depositAmount:item.rechargeAmount,
                                status:item.status == 1 ? this.$t("CompletePayment") : item.status == 2 ? this.$t("TimeoutCancelled") : this.$t("UnPayOrderTips"),
                                statusc:item.status == 1 ? 'green' : item.status == 2 ? 'gray' : 'red',
                            }
                        })];
                        this.page += 1;
                        this.total = res.data.total;
                        this.loading = false;
                    }).catch(err => {
                        this.loading = false;
                    })
                }
            },
            getGameRecordList(){ // 加载游戏记录列表
                if(!this.loading){
                    this.loading = true;
                    let data;
                    if(this.activeType!= -1){
                        data = {
                            currentPage: this.page,
                            pageSize:this.size,
                            gameType:this.typeList[this.activeType].value,
                            "createTime[0]":this.searchInfo.createTime[0],
                            "createTime[1]":this.searchInfo.createTime[1],
                        };
                    }else{
                        data = {
                            currentPage: this.page,
                            pageSize:this.size,
                            "createTime[0]":this.searchInfo.createTime[0],
                            "createTime[1]":this.searchInfo.createTime[1],
                        };
                    }
                    GameRecordList(data).then(res => {
                        this.data = [...this.data,...res.data.records.map(item => {
                            return {
                                id:item.id,
                                date:item.changeTime ? formatDateTimeNotYearSimple(item.changeTime,true) : '',
                                // date:formatDateTimeNotYearSimple(item.changeTime,true),
                                type:formatWord(this.getGameType(item.gameType)),
                                bets:item.bet,
                                bonus:item.winAmount,
                                winloss:item.transferAmount,
                                changeType:item.changeType
                            }
                        })]
                        this.page += 1;
                        this.total = res.data.total;
                        this.loading = false;
                    }).catch(err => {
                        this.loading = false;
                    })
                }
            },
            getBalanceChangeList(){ // 加载账户变动列表
                if(!this.loading){
                    this.loading = true;
                    const data = {
                        currentPage: this.page,
                        pageSize:this.size,
                        "createTime[0]":this.searchInfo.createTime[0],
                        "createTime[1]":this.searchInfo.createTime[1],
                    };
                    BalanceChangeList(data).then(res => {
                        this.data = [...this.data,...res.data.records.map(item => {
                            return {
                                id:item.id,
                                date:item.changeTime ? formatDateTimeNotYearSimple(item.changeTime,true) : '',
                                // date:formatDateTimeNotYearSimple(item.changeTime,true),
                                type:formatWord(item.optTypeName),
                                amount:item.changeAmount
                            }
                        })]
                        this.page += 1;
                        this.total = res.data.total;
                        this.loading = false;
                    }).catch(err => {
                        this.loading = false;
                    })
                }
            },
            getBonusRecordList(){ // 加载奖金列表
                if(!this.loading){
                    this.loading = true;
                    const data = {
                        currentPage: this.page,
                        pageSize:this.size,
                        "createTime[0]":this.searchInfo.createTime[0],
                        "createTime[1]":this.searchInfo.createTime[1],
                    };
                    BonusRecord(data).then(res => {
                        this.data = [...this.data,...res.data.records.map(item => {
                            return {
                                id:item.taskId,
                                date:item.createTime ? formatDateTimeNotYearSimple(item.createTime,true) : '',
                                source:formatWord(item.taskName),
                                bonus:item.rewardAmount,
                                status:item.status == 0 ? this.$t("TimeoutCancelled") : item.status == 2 ? this.$t("Unresolved") : this.$t("CompletePayment"),
                                statusc:item.status == 0 ? 'red' : item.status == 2 ? 'gray' : 'green',
                            }
                        })];
                        this.page += 1;
                        this.total = res.data.total;
                        this.loading = false;
                    }).catch(err => {
                        this.loading = false;
                    })
                }
            },
            getUserTaskInfoList(){
                if(!this.loading){
                    this.loading = true;
                    const data = {
                        currentPage: this.page,
                        pageSize:this.size,
                        "createTime[0]":this.searchInfo.createTime[0],
                        "createTime[1]":this.searchInfo.createTime[1],
                    };
                    UserTaskInfo(data).then(res => {
                        this.data = [...this.data,...res.data.records.map(item => {
                            return {
                                id:item.taskId,
                                // date:formatDateTimeNotYearSimple(item.createTime,true),
                                date:item.createTime ? formatDateTimeNotYearSimple(item.createTime,true) : '',
                                type:formatWord(item.taskName),
                                bonus:item.rewardAmount,
                                details:{
                                    component: SimpleProcess,  // 组件引用
                                    props: { 
                                      son:item.completedAmount,
                                      father:item.targetTurnover
                                    }  // 传递给组件的参数
                                }
                            }
                        })];
                        this.page += 1;
                        this.total = res.data.total;
                        this.loading = false;
                    }).catch(err => {
                        this.loading = false;
                    })
                }
            },
            initList(){
                switch(this.$route.query.type){
                    case "withdraw":
                        this.current = 2;
                        // 获取提现记录
                        this.getWithdrawList();
                        break;
                    case "bets":
                        this.current = 3;
                        // 获取游戏记录列表
                        this.getGameRecordList();
                        break;
                    case "bonus":
                        this.current = 4;
                        this.getBonusRecordList();
                        break;
                    case "account":
                        this.current = 0;
                        // 获取账户资金变动列表
                        this.getBalanceChangeList();
                        break;
                    case "promotion":
                        this.current = 5;
                        // 获取用户任务列表
                        this.getUserTaskInfoList();
                        break;
                    default:
                        this.current = 1;
                        // 获取充值记录
                        this.getDepositList();
                        break;
                }
            }
        },
        async mounted(){
            this.initGameType();
            this.initList();
        }
    }
</script>

<style scoped>

    .red{
        color:var(--error) !important;
    }

    .green{
        color:var(--success) !important;
    }

    .fixedStatisticsLeftTotal{
        margin-top:0.3rem;
        color:var(--subfont);
        font-weight:bold;
        font-size:0.9rem;
    }

    .fixedStatisticsLeftDate{
        color:var(--gdient2);
        font-weight:bold;
        font-size:1.2rem;
    }

    .fixedStatisticsRightItem{
        display:flex;
        align-items: center;
        color:var(--basic);
        font-weight:bold;
        width:50%;
        justify-content: center;
        font-size:1.1rem;
    }

    .fixedStatisticsRight{
        display:flex;
        align-items: center;
        width:40%;
    }

    .fixedStatisticsLeft{
        display:flex;
        flex-direction: column;
    }

    .fixedStatistics{
        height:fit-content;
        width:100%;
        position:absolute;
        z-index:10;
        bottom:8rem;
        left:0;
        background:var(--primary);
        box-sizing:border-box;
        padding:0 2rem;
        padding-top:1rem;
        display:flex;
        align-items: center;
        justify-content: space-between;
    }

    .loadingMore {
        display: inline-block;
        color: var(--subfont);
        background: var(--primary);
        border: 0.1rem solid var(--primary);
        border-radius: 1rem;
        margin: 2rem auto;
        padding: 1rem 2rem;
        font-size: 1.5rem;
    }

    .searchButton {
        background: var(--blue);
        color: #fff;
        border-radius: 1rem;
        box-sizing: border-box;
        padding: 0 2.5rem;
        height: 3.5rem;
        font-size: 1.3rem;
        display: flex;
        align-items: center;
        margin-right: 1rem;
    }

    .searchBox{
        display:flex;
        align-items: center;
        margin-top:1rem;
        flex-wrap:wrap;
    }

    .classItem.active{
        background:var(--gdient2);
        color:#fff;
    }
    .classItem:nth-child(3n){
        margin-right:0 !important;
    }
    .classItem{
        width:calc((100% - 2rem) / 3);
        height:4rem;
        background:var(--primary);
        display:flex;
        align-items: center;
        color:var(--basic);
        font-weight:bold;
        font-size:1.2rem;
        margin-right:1rem;
        margin-bottom:1rem;
        border-radius:0.5rem;
        white-space: nowrap;
        justify-content: center;
        cursor:pointer;
    }
    .classList{
        display:flex;
        flex-wrap:wrap;
        align-items: center;
        height:fit-content;
        width:100%;
    }
    .box{
        display:flex;
        flex-direction: column;
        height:100%;
        width:100%;
        height:fit-content;
        box-sizing: border-box;
        padding:2rem 2rem;
        padding-bottom:14rem;
    }
</style>